// TODO: probably I don't need the quizzes in the user reducer, unless I break mk quizzes from video quizzes

function userReducer(state, action) {
  switch (action.type) {
    case "AUTHENTICATED-USER": {
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        isAdmin:
          action.payload &&
          action.payload["https://thedutchonlineacademy.com/app_metadata"] &&
          action.payload["https://thedutchonlineacademy.com/app_metadata"]
            .isAdmin
            ? action.payload["https://thedutchonlineacademy.com/app_metadata"]
                .isAdmin
            : false,
      };
    }
    case "USER-DATA-LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "USER-DATA-RECEIVED": {
      return {
        ...state,
        purchased: action?.payload?.purchased,
        invoices: action?.payload?.invoices,
        lessons: action?.payload?.lessons,
        comments: action?.payload?.comments,
        transactions: action?.payload?.transactions,

        quizzes: action?.payload?.quizzes,
      };
    }
    case "logout": {
      return {
        ...state,
        user: {},
        purchased: [],
        authenticated: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
