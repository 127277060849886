const appConnection = async (UID) => {
  try {
    const userOrdersResponse = await fetch(
      `${process.env.GATSBY_BACKEND_URL}/user/orders`,
      {
        headers: {
          token: UID,
        },
      },
    );
    const userOrders = await userOrdersResponse.json();
    return userOrders;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("IT EXPLODED", e);
  }
};

export default appConnection;
