const pushEvent = ({ event, location, action, label }: any) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      data: {
        location,
        action,
        label,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

// category as in books/grammar/B1
// books/stories/A2

const pushImpressionView = ({ productList, location }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-product-list-view",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        // value: productList.reduce(
        //   (a: number, b: { price: number }) => a + b.price,
        //   0,
        // ),
        currency: "EUR",
        products: productList.map((product: any, index: any): any => ({
          id: product.id,
          name: product.name,
          price: product.price,
          category: product.category,
          position: index,
          quantity: 1,
        })),
      },
    });
  } catch (e) {
    console.log({ e });
  }
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.impressionView",
      ecommerce: {
        impressions: productList.map((product: any, index: any) => ({
          id: product.id,
          name: product.name,
          category: product.category,
          list: location["list-type"] ?? location,
          price: product.price,
          position: index,
        })),
      },
    });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: location,
        item_list_name: location,
        items: productList.map((product: any, index: any): any => ({
          index: index,
          item_id: product.id,
          item_list_id: location,
          item_name: product.name,
          item_category: product.category,
          price: product.price,
          currency: "EUR",
          quantity: 1,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log({ e });
  }
};
const pushImpressionClick = ({ productList, location }: any) => {
  // try {
  //   window.pocket = window.pocket || [];
  //   window.pocket.push({
  //     eventType: "ecom-product-list-click",
  //     ecommerce: {
  //       location: {
  //         "list-id": location,
  //         "list-name": location,
  //       },
  //       // value: productList.reduce(
  //       //   (a: number, b: { price: number }) => a + b.price,
  //       //   0,
  //       // ),
  //       currency: "EUR",
  //       products: productList.map((product: any, index: any): any => ({
  //         id: product.id,
  //         name: product.name,
  //         price: product.price,
  //         category: product.category,
  //         position: index,
  //         quantity: 1,
  //       })),
  //     },
  //   });
  // } catch (e) {
  //   console.log({ e });
  // }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.impressionClick",
      ecommerce: {
        click: {
          actionField: {
            list: location,
          },
          products: productList.map((product: any, index: any) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            list: location,
            price: product.price,
            position: index,
            currency: "EUR",
          })),
        },
      },
    });

    window.dataLayer.push({
      event: "item_list_id",
      ecommerce: {
        item_list_id: location,
        item_list_name: location,
        items: productList.map((product: any, index: any) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: product.category,
          list: location,
          price: product.price,
          quantity: 1,
          position: index,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

const pushDetailView = ({ id, name, category, price, location }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-product-detail-view",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        // value: productList.reduce(
        //   (a: number, b: { price: number }) => a + b.price,
        //   0,
        // ),
        currency: "EUR",
        products: [
          {
            id: id,
            name: name,
            price: price,
            category: category,
          },
        ],
      },
    });
  } catch (e) {
    console.log({ e });
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.detail",
      ecommerce: {
        detail: {
          actionField: {
            list: location,
          },
          products: [
            {
              id,
              name,
              category,
              type: "Ecommerce",
              price,
            },
          ],
        },
      },
    });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: price,
        items: [
          {
            item_id: id,
            item_name: name,
            currency: "EUR",
            item_category: category,
            index: 0,
            item_list_name: "Product Detail Page",
            price: price,
          },
        ],
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

// logs the new products in the cart (not the total!!)
const pushAddToCart = ({ productList }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-product-add-to-cart",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        currency: "EUR",
        products: productList.map(
          (product: {
            id: string;
            name: string;
            price: number;
            category: string;
            quantity: number;
          }) => ({
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          }),
        ),
      },
    });
  } catch (e) {
    console.log({ e });
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.add",
      ecommerce: {
        add: {
          products: productList.map((product: any, index: number) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        items: productList.map((product: any, index: number) => ({
          item_id: product.id,
          item_name: product.name,
          google_business_vertical: "retail",
          item_category: product.category,
          price: product.price,
          currency: "EUR",
          quantity: product.quantity,
        })),
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

const pushRemoveFromCart = ({ productList }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-product-remove-from-cart",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        currency: "EUR",
        products: productList.map(
          (product: {
            id: string;
            name: string;
            price: number;
            category: string;
            quantity: number;
          }) => ({
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          }),
        ),
      },
    });
  } catch (e) {
    console.log({ e });
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.remove",
      ecommerce: {
        remove: {
          actionField: {
            list: "Shopping cart",
          },
          products: productList.map((product: any, index: any) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: "remove_to_cart",
      ecommerce: {
        currency: "EUR",
        items: productList.map((product: any, index: any) => ({
          item_id: product.id,
          item_name: product.name,
          google_business_vertical: "retail",
          item_category: product.category,
          price: product.price,
          currency: "EUR",
          quantity: product.quantity,
        })),
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

const pushGoToCheckout = ({ step, productList, action }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-begin-checkout",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        currency: "EUR",
        products: productList.map(
          (product: {
            id: string;
            name: string;
            price: number;
            category: string;
            quantity: number;
          }) => ({
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          }),
        ),
      },
    });
  } catch (e) {
    console.log({ e });
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step,
            action,
          },
          products: productList.map((product: any, index: any) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            price: product.price,
            quantity: product.quantity,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};
const pushBeginCheckout = ({ step, productList, action }: any) => {
  // try {
  //   window.pocket = window.pocket || [];
  //   window.pocket.push({
  //     eventType: "ecom-checkout-step",
  //     ecommerce: {
  //       location: {
  //         "list-id": location,
  //         "list-name": location,
  //       },
  //       step: step,
  //       value: productList.reduce(
  //         (a: number, b: { price: number }) => a + b.price,
  //         0,
  //       ),
  //       currency: "EUR",
  //       products: productList.map(
  //         (product: {
  //           id: string;
  //           name: string;
  //           price: number;
  //           category: string;
  //           quantity: number;
  //         }) => ({
  //           id: product.id,
  //           name: product.name,
  //           price: product.price,
  //           category: product.category,
  //           quantity: product.quantity,
  //         }),
  //       ),
  //     },
  //   });
  // } catch (e) {
  //   console.log({ e });
  // }

  // only used in the GA4 tracking
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "EUR",
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        items: productList.map((product: any, index: number) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: product.category,
          price: product.price,
          quantity: product.quantity,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};
const pushViewCart = ({ step, productList, action }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-view-cart",
      ecommerce: {
        location: {
          "list-id": location,
          "list-name": location,
        },
        step: step,
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        currency: "EUR",
        products: productList.map(
          (product: {
            id: string;
            name: string;
            price: number;
            category: string;
            quantity: number;
          }) => ({
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          }),
        ),
      },
    });
  } catch (e) {
    console.log({ e });
  }

  // only used in the GA4 tracking
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "EUR",
        value: productList.reduce(
          (a: number, b: { price: number }) => a + b.price,
          0,
        ),
        items: productList.map((product: any, index: number) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: product.category,
          price: product.price,
          quantity: product.quantity,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

// price as in '11.00'
const pushPurchase = ({ orderId, orderRevenue, productList }: any) => {
  try {
    window.pocket = window.pocket || [];
    window.pocket.push({
      eventType: "ecom-purchase",
      ecommerce: {
        id: orderId,
        value: orderRevenue,
        currency: "EUR",
        products: productList.map(
          (product: {
            id: string;
            name: string;
            price: number;
            category: string;
            quantity: number;
          }) => ({
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          }),
        ),
      },
    });
  } catch (e) {
    console.log({ e });
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.purchase",
      ecommerce: {
        currencyCode: "EUR",
        purchase: {
          actionField: {
            id: orderId,
            // affiliation: "Simo's shop",
            revenue: orderRevenue,
            // tax: "1.00",
            // shipping: "2.00",
            // coupon: "SUMMER2019",
          },
          products: productList.map((product: any, index: number) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: orderId,
        value: orderRevenue,
        tax: 0,
        shipping: 0,
        currency: "EUR",
        items: productList.map((product: any, index: number) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: product.category,
          quantity: product.quantity,
          price: product.price,
          index: index,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking js": e });
  }
};

export {
  pushImpressionView,
  pushImpressionClick,
  pushDetailView,
  pushAddToCart,
  pushRemoveFromCart,
  pushViewCart,
  pushBeginCheckout,
  pushGoToCheckout,
  pushPurchase,
  pushEvent,
};
