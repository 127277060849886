function userReducer(state, action) {
  switch (action.type) {
    case "USER-DATA-RECEIVED": {
      return {
        ...state,
        completedLessons: action.payload.lessons,
        quizzes: action?.payload?.quizzes,
      };
    }

    case "UNDO-LESSON": {
      const { lessonId } = action.payload;
      if (state.completedLessons && state.completedLessons.length > 0) {
        return {
          ...state,
          completedLessons: [
            ...state.completedLessons.filter((l) => l.lessonId !== lessonId),
          ],
        };
      } else {
        // eslint-disable-next-line no-console
        console.error("No lesson to undo...");
        return state;
      }
    }

    case "COMPLETE-LESSON": {
      const { lessonId } = action.payload;
      if (state.completedLessons && state.completedLessons.length > 0) {
        return {
          ...state,
          completedLessons: [
            ...state.completedLessons,
            { lessonId, timestamp: Date.now() },
          ],
        };
      } else {
        return {
          ...state,
          completedLessons: [{ lessonId, timestamp: Date.now() }],
        };
      }
    }

    case "UNDO-QUIZ": {
      const { quizId } = action.payload;
      return {
        ...state,
        quizzes: [...state.quizzes.filter((q) => q.quizId !== quizId)],
      };
    }

    default: {
      return state;
    }
  }
}

export default userReducer;
