/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import AppWrapper from "./src/context/appProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";
const AuthProvider = (props) => {
  const [currentUrl, set_currentUrl] = useState(
    `${process.env.GATSBY_ROOT_URL}/en/user`,
  );

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || "/en/user", { replace: true });
  };

  useEffect(() => {
    set_currentUrl(window.location.href);
  }, [window.location.href]);

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      redirectUri={currentUrl}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <AppWrapper>{props.children}</AppWrapper>
    </Auth0Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <AuthProvider>{element}</AuthProvider>;
};
