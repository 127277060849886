import rmvObjInArr from "../../utils/rmvObjInArr";
import {
  pushAddToCart,
  pushRemoveFromCart,
  pushPurchase,
} from "../../utils/data-layer-ecom";

interface CleanCartAction {
  type: "CLEAN-CART";
  payload: {};
}

// interface CartItem {
//   productId: string;
// }

interface RecoverCartAction {
  type: "RECOVER-CART";
  payload: {
    recoveredCart: any;
  };
}

interface RemoveFromCartAction {
  type: "REMOVE-FROM-CART";
  payload: {
    id: String;
    title: String;
    type: String;
    price: String;
  };
}
interface UserDataReceivedAction {
  type: "USER-DATA-RECEIVED";
  payload: {
    purchased: any;
  };
}
interface AddToCartAction {
  type: "ADD-TO-CART";
  payload: {
    id: String;
    title: String;
    type: String;
    price: String;
  };
}

type CommerceAction =
  | CleanCartAction
  | RecoverCartAction
  | RemoveFromCartAction
  | UserDataReceivedAction
  | AddToCartAction;

function commerceReducer(state, action: CommerceAction) {
  let newState = state;
  switch (action.type) {
    case "CLEAN-CART":
      {
        pushPurchase({
          orderId: Math.floor(Math.random() * (9999 - 1000)) + 1000,
          orderRevenue: state.total,
          productList: state.items.map((el) => ({
            id: el.id,
            name: el.title,
            category: el.type,
            quantity: 1,
          })),
        });

        newState = {
          items: [],
          total: 0,
          count: 0,
        };
      }
      break;
    case "RECOVER-CART":
      {
        newState = action.payload.recoveredCart;
      }
      break;
    case "ADD-TO-CART":
      {
        pushAddToCart({
          productList: [
            {
              id: action.payload.id,
              name: action.payload.title,
              category: action.payload.type,
              price: action.payload.price,
              quantity: 1,
            },
          ],
        });
        const newItemsArray = rmvObjInArr([...state.items, action.payload]);
        newState = {
          items: newItemsArray,
          total: newItemsArray.reduce((b, el) => b + el.price, 0),
          count: newItemsArray.length,
        };
      }
      break;
    case "REMOVE-FROM-CART":
      {
        pushRemoveFromCart({
          productList: [
            {
              id: action.payload.id,
              name: action.payload.title,
              category: action.payload.type,
              price: action.payload.price,
              quantity: 1,
            },
          ],
        });
        const newItemsArray = rmvObjInArr(
          state.items.filter((i) => i.id !== action.payload.id),
        );
        newState = {
          items: newItemsArray,
          total: newItemsArray.reduce((b, el) => b + el.price, 0),
          count: newItemsArray.length,
        };
        if (newState.count === 0) {
          localStorage.setItem("cart", JSON.stringify(newState));
        }
      }
      break;

    case "USER-DATA-RECEIVED": {
      if (
        !action ||
        !action.payload ||
        !action.payload.purchased ||
        !action.payload.purchased.length
      ) {
        return state;
      }

      const filteredArray = state.items.filter(
        (prodInCart) =>
          !action.payload.purchased.find((e) => e.productId === prodInCart.id),
      );

      const newState = {
        items: filteredArray,
        total: filteredArray.reduce((b, el) => b + el.price, 0),
        count: filteredArray.length,
      };
      // means the person bought all
      if (state.count > 0 && newState.count === 0) {
        localStorage.setItem("cart", JSON.stringify(newState));
      }

      return newState;
    }
    default: {
      // return state;
      // throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  if (window && newState.total > 0)
    localStorage.setItem("cart", JSON.stringify(newState));
  return newState;
}

export default commerceReducer;
